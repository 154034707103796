
import Vue, { PropType } from 'vue';

interface DataType {
  showOption: boolean;
  items: any;
  perPage: number;
}

export interface DisplayOptionItem {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export default Vue.extend({
  name: 'DisplayOption',
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    displayOptionItems: {
      type: Array as PropType<DisplayOptionItem[]>,
      default: () => [],
    },
    isShowOption: {
      type: Boolean,
    },
    isVaildOnly: {
      type: Boolean,
      default: true,
    },
    isVaildOnlyShow: {
      type: Boolean,
      default: true,
    },
    isLog: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    showOption: false,
    perPage: 100,
    items: [
      {text: '25 件' , value: 25},
      {text: '50 件' , value: 50},
      {text: '100 件' , value: 100},
      {text: '1000 件' , value: 1000},
    ],
  }),
  computed: {
    innerValue: {
      get(): string[] {
        return this.value;
      },
      set(val: string[]): void {
        this.$emit('input', val);
        return;
      },
    },
    perPageValue: {
      get(): number {
        return this.perPage;
      },
      set(val: number): void {
        this.$emit('change-per-page-log', val);
        return;
      },
    },
    isValid: {
      get(): boolean {
        return this.isVaildOnly;
      },
      set(val: boolean): void {
        this.$emit('show-only-isVaild', val);
        return;
      },
    },
  },
  methods: {
    onClickOutsideOption() {
      if (this.showOption) {
        this.showOption = false;
      }
    },
    included() {
      return [document.querySelector('.included-option')];
    },
  },
});

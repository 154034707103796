
import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import * as Config from '@/config';
export interface DataType {
  searchInput: string;
  userTypeOptions: any;
  rulesValidate: any;
  configData: any;
  priviledge: any;
}
interface User {
  userId: string;
  // password: string;
  userName: string;
  priviledge: string;
  furigana: string;
  email: string;
  userType: string;
  organizations: string;
  supplement: string;
}

export default Vue.extend({
  name: 'UserAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    TextLink,
  },
  props: {
    showModalAddEditUser: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    // userTypeOptions: {
    //   type: Array,
    //   default: () => [],
    // },
    organizations: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: String,
      default: null,
    },
    userInfo: {
      type: Object as PropType<User>,
      default: () => ({
        userId: '',
        // password: string,
        userName: '',
        priviledge: '',
        furigana: '',
        email: '',
        userType: '',
        organizations: [],
        supplement: '',
      }),
    },
    isResetData: {
      default: false,
    },
    changeUserInfoDialog: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    //
  },
  mounted() {
    //
  },
  computed: {
    //
    isDisabled: {
      get(): boolean {
        let isValid = true;
        isValid = !!this.userInfo.userId;
        if (isValid) {
          const pattern = /^[a-z\d\-_\s]{4,80}$/i;
          isValid = isValid && pattern.test(this.userInfo.userId);
        }
        isValid = isValid && !!this.userInfo.userName;
        isValid = isValid && (this.userInfo.userName.length <= Config.USER_NAME_MAX_LEN);
        isValid = isValid && (this.userInfo.furigana.length <= Config.USER_FURIGANA_MAX_LEN);
        if (this.userInfo.email) { // 空文字列はスルー
          if (typeof this.userInfo.email !== 'string' ||
              this.userInfo.email.length > 80 ||
              !this.userInfo.email.match('^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$')) {
            isValid = false;
          } else {
            isValid = isValid && (this.userInfo.email.length <= Config.EMAIL_MAX_LEN);
          }
        }
        if (this.userInfo.organizations !== undefined && this.userInfo.organizations !== null) {
          if (Array.isArray(this.userInfo.organizations) && this.userInfo.organizations.length > 10) {
            isValid = false;
          }
        }
        isValid = isValid && (this.userInfo.supplement.length <= Config.MEMO_MAX_LEN);
        return !isValid;
      },
      set(): void {
        return;
      },
    },
  },
  watch: {
    isResetData(value) {
      if (value) {
        (this.$refs.UserAddForm as Vue & { resetValidation: () => boolean }).resetValidation();
      }
    },
  },
  data: (): DataType => ({
    searchInput: '',
    userTypeOptions: [
      { text: Config.TEXT_GENERALLY, value: 0 },
      { text: Config.TEXT_ACKNOWLEDGMENT, value: 1 },
      { text: Config.TEXT_USE_MANAGER, value: 2 },
    ],
    rulesValidate: {
      requiredUserId: (userId: string) => !!userId || Config.ERROR_USER_ID_REQUIRED,
      validateUserId: (userId: string) => {
        const pattern = /^[a-z\d\-_\s]{4,80}$/i;
        return pattern.test(userId) || Config.ERROR_USER_ID_LENGTH;
      },
      requiredName: (name: string) => !!name || Config.ERROR_USER_NAME_REQUIRED,
      validateName: (name: string) => {
        if (name !== undefined && name !== null && name !== '') {
          return name.length <= Config.USER_NAME_MAX_LEN || Config.ERROR_USER_NAME_LENGTH;
        } else {
          return true;
        }
      },
      validateFurigana: (furigana: string) => {
        if (furigana !== undefined && furigana !== null && furigana !== '') {
          return furigana.length <= Config.USER_FURIGANA_MAX_LEN || Config.ERROR_FURIGANA_LENGTH;
        } else {
          return true;
        }
      },
      validateEmail: (email: string) => {
        if (email) { // 空文字列はスルー
          if (typeof email !== 'string' ||
              email.length > 80 ||
              !email.match('^[a-zA-Z0-9_+-]+(\\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\\.)+[a-zA-Z]{2,}$')) {
            return Config.ERROR_EMAIL_VALIDATE;
          } else {
            return email.length <= Config.EMAIL_MAX_LEN || Config.ERROR_EMAIL_LENGTH;
          }
        } else {
          return true;
        }
      },
      // validateOrganizations: (organizations: string) => {
      //     if (organizations !== undefined && organizations !== null && organizations !== '') {
      //       return organizations.length <= 80 || Config.ERROR_ORGANIZATIONS_LENGTH;
      //     } else {
      //       return true;
      //   }
      // },
      validateRemarks: (remarks: string) => {
        if (remarks !== undefined && remarks !== null && remarks !== '') {
          return remarks.length <= Config.MEMO_MAX_LEN || Config.ERROR_REMARKS_LENGTH_USER;
        } else {
          return true;
        }
      },
      // validatePassword: (pass: string) => {
      //   const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
      //   return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
      // },
    },
    configData: Config,
    priviledge: sessionStorage.getItem('priviledge'),
  }),
  methods: {
    validateCompanyDepartment(data: any) {
      if (data !== undefined && data !== null) {
        if (Array.isArray(data) && data.length > 10) {
          alert(Config.ERROR_COMPANY_DEPARTMENT_LENGTH);
        }
      }
    },
    showUserChangeConfirm() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('showUserChangeConfirm');
      }
    },
    closeUserChangeConfirm() {
      this.$emit('closeUserChangeConfirm');
    },
    closeAddEditUserDialog() {
      this.$emit('closeAddEditUserDialog');
    },
    clickSaveUser() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickSaveUser', {
          userInfo: this.userInfo,
        });
      }
    },
    clickUpdateUser() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickUpdateUser', {
          userInfo: this.userInfo,
        });
      }
    },
  },
});

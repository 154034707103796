import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import * as Config from '@/config';
import Store from '@/store';
import LoginPage from '@/components/pages/login/LoginPage.vue';
import ChangeInitialPasswordPage from '@/components/pages/change-initial-password/ChangeInitialPasswordPage.vue';
import LayoutTemplate from '../components/pages/layout/LayoutPage.vue';
import Applicant from '@/components/pages/applicant/ApplicantListPage.vue';
import ApplicantViewPage from '@/components/pages/applicant/ApplicantViewPage.vue';
import ApplicantAddPage from '@/components/pages/applicant/ApplicantAddPage.vue';
import ApprovalListPage from '@/components/pages/approval/ApprovalPage.vue';
// import ApprovalAddPage from '@/components/pages/approval/ApprovalAddPage.vue';
import ApprovalStatusPage from '@/components/pages/approval-status/ApprovalStatusPage.vue';
import CardListPage from '@/components/pages/card/CardListPage.vue';
import EntranceHistoryListPage from '@/components/pages/entrance-history/EntranceHistoryListPage.vue';
import EquipmentStatusListPage from '@/components/pages/equipment-status/EquipmentStatusListPage.vue';
import ApproverSettingsListPage from '@/components/pages/approver-settings/ApproverSettingsListPage.vue';
import OfficeListPage from '@/components/pages/office/OfficeListPage.vue';
import UserManagementPage from '@/components/pages/user/UserManagementPage.vue';
import LogPage from '@/components/pages/log/LogPage.vue';
import EmailSettingsPage from '@/components/pages/admin/EmailSettingsPage.vue';
import ManualPage from '@/components/pages/manual/ManualPage.vue';
import auth from '@/auth';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: Config.LOGIN,
    name: 'login',
    component: LoginPage,
    beforeEnter: (to: any, from: any, next: any) => {
      if (to.query.clear !== null && to.query.clear !== undefined && to.query.clear !== ''
      && to.query.clear === 'true') {
        auth.clearAllData();
        sessionStorage.setItem('ownerId', to.query.kanriId.toString());
        sessionStorage.setItem('userId', to.query.userId.toString());
        router.push({
          path: Config.LOGIN, query: {
            kanriId: to.query.kanriId,
            userId: to.query.userId,
          },
        });
      }
      if (from.name !== '' && from.name !== null) {
        auth.clearAllData();
      }
      if (!sessionStorage.hasOwnProperty('isLoggedIn') || sessionStorage.getItem('isLoggedIn') !== 'true'
      || !sessionStorage.hasOwnProperty('priviledge')) {
        // まだログイン場合
        next();
      } else {
        next({
          // ログインした場合
          path: Config.DASHBOARD,
        });
      }
    },
  },
  {
    path: Config.CHANGE_INITIAL_PASSWORD,
    name: 'change-initial-password',
    component: ChangeInitialPasswordPage,
    beforeEnter: (to: any, from: any, next: any) => {
      if (sessionStorage.getItem('newPasswordRequired') && sessionStorage.getItem('newPasswordRequired') === '1') {
        next();
      } else {
        next({
          // まだログイン場合
          path: Config.LOGIN,
        });
      }
    },
  },
  {
    path: '/',
    component: LayoutTemplate,
    children: [
      {
        path: '/',
        name: 'applicant',
        component: Applicant,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPLICANT,
        name: 'applicant',
        component: Applicant,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPLICANT_VIEW,
        name: 'applicant-view',
        component: ApplicantViewPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPLICANT_ADD,
        name: 'applicant-add',
        component: ApplicantAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPLICANT_EDIT,
        name: 'applicant-edit',
        component: ApplicantAddPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPROVAL,
        name: 'approval',
        component: ApprovalListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPROVAL_STATUS,
        name: 'approval-status',
        component: ApprovalStatusPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.ENTRANCE_HISTORY,
        name: 'entrance-history',
        component: EntranceHistoryListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.EQUIPMENT_STATUS,
        name: 'equipment-status',
        component: EquipmentStatusListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.APPROVER_SETTINGS,
        name: 'approver-settings',
        component: ApproverSettingsListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.USER_LIST,
        name: 'user',
        component: UserManagementPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.OFFICE,
        name: 'office',
        component: OfficeListPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.LOG,
        name: 'log',
        component: LogPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.EMAIL_SETTINGS,
        name: 'email-settings',
        component: EmailSettingsPage,
        beforeEnter: requireAuthRole,
      },
      {
        path: Config.PERSONAL_SETTING,
        name: 'personal',
        component: CardListPage,
        beforeEnter: requireLogin,
      },
      {
        path: Config.MANUAL,
        name: 'manual',
        component: ManualPage,
        beforeEnter: requireAuthRole,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

function requireLogin(to: any, from: any, next: any) {
  if (!sessionStorage.hasOwnProperty('isLoggedIn') || sessionStorage.getItem('isLoggedIn') !== 'true'
    || !sessionStorage.hasOwnProperty('priviledge')) {
    next({
      // まだログイン場合
      path: Config.LOGIN,
    });
  } else {
    next();
  }
}
function requireAuthRole(to: any, from: any, next: any) {
  console.log(to);
  if (!sessionStorage.hasOwnProperty('isLoggedIn') || sessionStorage.getItem('isLoggedIn') !== 'true'
    || !sessionStorage.hasOwnProperty('priviledge')) {
    next({
      // まだログイン場合
      path: Config.LOGIN,
    });
  } else {
    if (
    (sessionStorage.getItem('priviledge') === '3' && sessionStorage.getItem('type') === '0') || (
      sessionStorage.getItem('priviledge') === '2' && sessionStorage.getItem('office_code') === ''
    )) {
      if (to.fullPath === '/manual') {
        next();
      } else {
      next({
        path: Config.DASHBOARD,
      });
    }
    } else {
    const arr1 = [
      'personal',
      'approval',
      'log', 'email-settings', 'office', 'approval-status', 'entrance-history', 'equipment-status', 'user', 'approver-settings', 'manual',
    ];
    const arr2 = ['personal', 'approval-status', 'approval', 'entrance-history', 'equipment-status', 'user', 'approver-settings', 'manual'];
    const arr3 = [
      'personal',
      'applicant', 'applicant-view', 'applicant-add', 'applicant-edit', 'manual',
    ];
    const routeToPage = sessionStorage.getItem('RouteToPage');
    sessionStorage.removeItem('RouteToPage');
    if (routeToPage) {
      console.log('Route to ' + routeToPage);
      next({
        path: routeToPage,
      });
    } else {
      if ((sessionStorage.getItem('priviledge') === '3' && arr3.includes(to.name)) ||
        (sessionStorage.getItem('priviledge') === '2' && arr2.includes(to.name)) ||
        ((sessionStorage.getItem('priviledge') === '1' ||
          sessionStorage.getItem('priviledge') === '0') && arr1.includes(to.name))) {
        next();
      } else {
        next({
          path: Config.LOGIN,
        });
      }
    }
  }
  }
}
export default router;

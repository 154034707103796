var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.loading === false
        ? _c(
            "div",
            { staticClass: "pl-2" },
            [
              _vm.resData.status === "saved" ||
              _vm.resData.status === "returned"
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                      _vm.resData.status !== "returned"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-primary",
                              attrs: {
                                outlined: "",
                                disabled:
                                  _vm.resData.listGates === undefined ||
                                  _vm.resData.listGates === null ||
                                  _vm.resData.listGates === "" ||
                                  _vm.resData.description === "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showDialog("submissions")
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.configData.TEXT_APPLICATION) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickEdit(_vm.resData.request_id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_BUTTON_EDIT) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.resData.status === "submitted"
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.showDialog("cancellations")
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_BUTTON_CANCEL) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.showDialog("resend-email", true)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.configData.TEXT_BUTTON_RESEND_EMAIL) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.resData.status === "approved"
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            outlined: "",
                            disabled: _vm.resExtData.status === "waiting",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.showExtensions(_vm.resData.end)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_BUTTON_EXTEN) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.resData.status === "canceled"
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.resData.status === "rescinded"
                ? _c(
                    "v-row",
                    { staticClass: "mb-2 mt-2", attrs: { justify: "start" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              return _vm.clickClose()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.configData.TEXT_CLOSE_UP) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-spacer"),
      _c(
        "v-card",
        { staticClass: "pa-6 pr-10 mb-5 card detail-content" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_APPLICATION_NUMBER)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.resData.request_id))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_STATUS)),
                  ]),
                ],
                1
              ),
              _vm.resData.status === "submitted" ||
              _vm.resData.status === "saved"
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "text-body-2 align-self-center submitted-saved",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.convertStatus(_vm.resData.status)) +
                          " "
                      ),
                    ]
                  )
                : _vm.resData.status === "approved" &&
                  this.resExtData.status === "waiting"
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center approved",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.convertStatus(_vm.resData.status)) +
                          " "
                      ),
                    ]
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                      attrs: { cols: "12", md: "10" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.convertStatus(_vm.resData.status)) +
                          " "
                      ),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_BEGIN_DATE)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.converDateShort(_vm.resData.begin)))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_END_DATE)),
                  ]),
                ],
                1
              ),
              _vm.resData.status === "saved"
                ? _c(
                    "v-col",
                    {
                      staticClass:
                        "text-body-2 align-self-center submitted-saved",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.converDateShort(_vm.resData.end)) + " "
                      ),
                    ]
                  )
                : _vm.resData.status === "approved" &&
                  this.resExtData.status === "waiting"
                ? _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center approved",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.converDateShort(_vm.resData.end)) + " "
                      ),
                    ]
                  )
                : _c(
                    "v-col",
                    {
                      staticClass: "text-body-2 align-self-center",
                      class: {
                        "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown,
                      },
                      attrs: { cols: "12", md: "10" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.converDateShort(_vm.resData.end)) + " "
                      ),
                    ]
                  ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_MANAGEMENT_OFFICE)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.resData.office_name))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_AREA_NAME)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.resData.area_name))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_GATE_NAME)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.resData.listGates === undefined ||
                          _vm.resData.listGates === null
                          ? ""
                          : _vm.resData.listGates
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_USER_ID)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [
                  _vm.loading
                    ? _c("span")
                    : _vm.resData.requester_uid !== undefined &&
                      _vm.resData.requester_uid !== null &&
                      _vm.resData.requester_uid !== ""
                    ? _c("span", [_vm._v(_vm._s(_vm.resData.requester_uid))])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.configData.TEXT_USER_DELETED)),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_USER_NAME)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [
                  _vm.loading
                    ? _c("span")
                    : _vm.resData.requester_name !== undefined &&
                      _vm.resData.requester_name !== null &&
                      _vm.resData.requester_name !== ""
                    ? _c("span", [_vm._v(_vm._s(_vm.resData.requester_name))])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.configData.TEXT_USER_DELETED)),
                      ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_COL_COMPANY_DEPARTMENT)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.resData.organizations === undefined ||
                          _vm.resData.organizations === null
                          ? ""
                          : _vm.resData.organizations
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [_c("v-subheader", [_vm._v(_vm._s(_vm.configData.TEXT_CARD))])],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.resCardData.masked_idi1) +
                      " " +
                      _vm._s(_vm.resCardData.masked_idi2) +
                      " " +
                      _vm._s(_vm.resCardData.masked_idi3) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_WORK_CONTENT)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.resData.description))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_DATE_APPLICATION)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.converDateShort(_vm.resData.submitted)))]
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "2" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.configData.TEXT_UPDATE_DATE)),
                  ]),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "text-body-2",
                  class: { "pl-10 pt-0": _vm.$vuetify.breakpoint.smAndDown },
                  attrs: { cols: "12", md: "10", "align-self": "center" },
                },
                [_vm._v(_vm._s(_vm.converDateShort(_vm.resData.updated)))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.titleChange === "3"
        ? _c(
            "div",
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.configData.TEXT_APPLICATION_HISTORY)),
              ]),
              _c("v-divider", { staticClass: "hr" }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.configData.TEXT_ENTRANCE_HISTORY)),
              ]),
              _c("v-divider", { staticClass: "hr" }),
            ],
            1
          ),
      _c(
        "v-card",
        [
          _c(
            "v-data-table",
            {
              staticClass: "border-table scroll-table",
              attrs: {
                "must-sort": "",
                headers: _vm.headers,
                items: _vm.resHisData,
                "items-per-page": -1,
                "item-key": "trail_id",
                "no-data-text": _vm.configData.TEXT_NO_DATA,
                "loading-text": _vm.configData.TEXT_LOADING_DATA,
                "hide-default-footer": "",
                "sort-by": ["timestamp"],
                "sort-desc": true,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: `item.timestamp`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.converDateShort(item.timestamp)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.content`,
                    fn: function ({ item }) {
                      return [_vm._v(" " + _vm._s(item.content) + " ")]
                    },
                  },
                  {
                    key: `item.from`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.convertStatus(item.from)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `item.to`,
                    fn: function ({ item }) {
                      return [
                        _vm._v(" " + _vm._s(_vm.convertStatus(item.to)) + " "),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [_vm._v("n ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmDialog,
            callback: function ($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [_vm._v(" " + _vm._s(_vm.configData.TEXT_CONFIRMATION) + " ")]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _vm.resData.status === "saved"
                    ? _c("div", [
                        _vm.endTime < _vm.currentTime
                          ? _c("div", [
                              _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_TOP
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_BOTTOM
                                  )
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_REQUEST_SUBMIT_APPLICATION
                                  )
                                ),
                              ]),
                            ]),
                      ])
                    : _vm.resData.status === "submitted"
                    ? _c("div", [
                        _vm.actions === "resend-email"
                          ? _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.configData.TEXT_TITLE_RESEND_EMAIL
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("div", [
                              _vm.isResend
                                ? _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.configData
                                          .TEXT_REQUEST_SUBMIT_APPLICATION
                                      )
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.configData
                                          .TEXT_REQUEST_CANCEL_APPLICATION
                                      )
                                    ),
                                  ]),
                            ]),
                      ])
                    : _vm.resData.status === "returned"
                    ? _c("div", [
                        _vm.endTime < _vm.currentTime
                          ? _c("div", [
                              _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_TOP
                                  )
                                ),
                              ]),
                              _c("p", { staticClass: "error-text" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_ERROR_WORK_PERIOD_EXPIRED_RETURNED_APPLICATION_BOTTOM
                                  )
                                ),
                              ]),
                            ])
                          : _c("div", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.configData
                                      .TEXT_REQUEST_SUBMIT_APPLICATION
                                  )
                                ),
                              ]),
                            ]),
                      ])
                    : _vm.resData.status === "approved"
                    ? _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.configData.TEXT_REQUEST_SUBMIT_EXTENSION)
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-textarea", {
                    staticClass: "input-time input-comment",
                    attrs: {
                      color: "green",
                      outlined: "",
                      dense: "",
                      solo: "",
                      "hide-details": "auto",
                      autocomplete: "off",
                      maxlength: 240,
                      rows: "6",
                      disabled:
                        (_vm.endTime < _vm.currentTime &&
                          _vm.resData.status === "saved") ||
                        (_vm.endTime < _vm.currentTime &&
                          _vm.resData.status === "returned"),
                      label: _vm.configData.TEXT_LABEL_COMMENT,
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.confirmDialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_RETURN))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            outlined: "",
                            disabled:
                              _vm.endTime < _vm.currentTime &&
                              _vm.resData.status === "saved"
                                ? true
                                : false ||
                                  (_vm.endTime < _vm.currentTime &&
                                    _vm.resData.status === "returned")
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.actionApproval()
                            },
                          },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "600" },
          model: {
            value: _vm.confirmDialogExtention,
            callback: function ($$v) {
              _vm.confirmDialogExtention = $$v
            },
            expression: "confirmDialogExtention",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "modal-title", attrs: { color: "black" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.configData.TEXT_APPLICATION_EXTENSION) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-5" },
                [
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _vm.endTime < _vm.currentTime
                    ? _c("div", [
                        _c("p", { staticClass: "error-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.configData
                                .TEXT_ERROR_WORK_PERIOD_EXPIRED_APPLICATION_TOP
                            )
                          ),
                        ]),
                        _c("p", { staticClass: "error-text" }, [
                          _vm._v(
                            _vm._s(
                              _vm.configData
                                .TEXT_ERROR_WORK_PERIOD_EXPIRED_APPLICATION_BOTTOM
                            )
                          ),
                        ]),
                      ])
                    : _c("div", [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.configData.TEXT_QUESTION_APPLY_EXTENSION)
                          ),
                        ]),
                      ]),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.itemWorkDate,
                      "hide-details": "auto",
                      outlined: "",
                      dense: "",
                      disabled: _vm.endTime < _vm.currentTime,
                    },
                    on: { change: _vm.ChangeDate },
                    model: {
                      value: _vm.workDate,
                      callback: function ($$v) {
                        _vm.workDate = $$v
                      },
                      expression: "workDate",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-text-field", {
                    staticClass: "input-time",
                    attrs: {
                      color: "green",
                      outlined: "",
                      dense: "",
                      "hide-details": "auto",
                      autocomplete: "off",
                      disabled: "",
                    },
                    model: {
                      value: _vm.extPeriodTime,
                      callback: function ($$v) {
                        _vm.extPeriodTime = $$v
                      },
                      expression: "extPeriodTime",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-textarea", {
                    staticClass: "input-time input-comment",
                    attrs: {
                      color: "green",
                      outlined: "",
                      dense: "",
                      solo: "",
                      "hide-details": "auto",
                      autocomplete: "off",
                      maxlength: 240,
                      rows: "6",
                      disabled:
                        (_vm.endTime < _vm.currentTime &&
                          _vm.resData.status === "saved") ||
                        (_vm.endTime < _vm.currentTime &&
                          _vm.resData.status === "returned"),
                      label: _vm.configData.TEXT_LABEL_COMMENT,
                    },
                    model: {
                      value: _vm.comment,
                      callback: function ($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment",
                    },
                  }),
                  _c("v-row", {
                    staticStyle: { height: "20px" },
                    attrs: { "no-gutters": "" },
                  }),
                  _c("v-spacer"),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-secondary",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.confirmDialogExtention = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.configData.TEXT_CANCEL))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-primary",
                          attrs: {
                            outlined: "",
                            disabled:
                              _vm.endTime < _vm.currentTime ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.approveExtensions()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.configData.TEXT_APPLICATION_EXTENSION)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import Vue from 'vue';
import * as Config from '@/config';
export default Vue.extend({
  name: 'SideNavigation',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    selectedItemNav: {
      type: String,
      default: '',
    },
    test: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerShow: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean): void {
        this.$emit('input', val);
        return;
      },
    },
  },
  data: () => ({
    mini : true,
    selectedItem: parseInt(sessionStorage.getItem('selectedItemNav') as string),
    menuName: sessionStorage.getItem('menuName'),
    priviledge: sessionStorage.getItem('priviledge'),
    // isAdmin: false,
    configData: Config,
  }),
  mounted() {
    //
  },
  methods: {
  },
  watch: {
    selectedItemNav(value) {
      console.log(value);
      this.selectedItem = parseInt(value);
    },
  },
});


import Vue from 'vue';
import * as Config from '@/config';
export default Vue.extend({
  name: 'Header',
  data: () => ({
    menuName: sessionStorage.getItem('menuName') ,
    isMobile:
    (sessionStorage.getItem('priviledge') === '3' && sessionStorage.getItem('type') === '0') ||
    (sessionStorage.getItem('priviledge') === '2' && sessionStorage.getItem('office_code') === '') ? false : true,
    priviledge: sessionStorage.getItem('priviledge'),
    // isAdmin: false,
    configData: Config,
  }),
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    userName: {
      type: String,
      default: '',
    },
    selectedItemNav: {
      type: String,
      default: '',
    },
  },
});
